<template>
  404
</template>

<script>
export default {
  name: "404"
};
</script>

<style scoped>

</style>